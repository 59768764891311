import "./styles.css";

const videoElem = document.getElementById("video");
const canvasContainer = document.getElementById("canvas-container");
// const logElem = document.getElementById("log");
const startElem = document.getElementById("start");
const stopElem = document.getElementById("stop");
const grabScreenImage = document.getElementById("grab");
const downloadCaptures = document.getElementById("download");
const CAPTURE_IMAGE_WIDTH = 1920;
const CAPTURE_IMAGE_HEIGHT = 1080;
const zip = new JSZip();

// console.log = (msg) => (logElem.innerHTML += `${msg}<br>`);
// console.error = (msg) =>
//   (logElem.innerHTML += `<span class="error">${msg}</span><br>`);
// console.warn = (msg) =>
//   (logElem.innerHTML += `<span class="warn">${msg}<span><br>`);
// console.info = (msg) =>
//   (logElem.innerHTML += `<span class="info">${msg}</span><br>`);

// Options for getDisplayMedia()
async function startCapture() {
  // logElem.innerHTML = "";

  try {
    videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(
      displayMediaOptions
    );
    dumpOptionsInfo();
  } catch (err) {
    console.error("Error: " + err);
  }
}

function stopCapture(evt) {
  if (videoElem.srcObject) {
    let tracks = videoElem.srcObject.getTracks();

    tracks.forEach((track) => track.stop());
    videoElem.srcObject = null;
  }
}

function dumpOptionsInfo() {
  const videoTrack = videoElem.srcObject.getVideoTracks()[0];

  console.info("Track settings:");
  console.info(JSON.stringify(videoTrack.getSettings(), null, 2));
  console.info("Track constraints:");
  console.info(JSON.stringify(videoTrack.getConstraints(), null, 2));
}

var displayMediaOptions = {
  video: {
    displaySurface: "browser",
    width: CAPTURE_IMAGE_WIDTH
  },
  audio: false
};

// Set event listeners for the start and stop buttons
startElem.addEventListener(
  "click",
  function (evt) {
    startCapture();
  },
  false
);

stopElem.addEventListener(
  "click",
  function (evt) {
    stopCapture();
  },
  false
);

grabScreenImage.addEventListener(
  "click",
  function (evt) {
    let canvas = document.createElement("canvas");
    canvas.width = CAPTURE_IMAGE_WIDTH;
    canvas.height = CAPTURE_IMAGE_HEIGHT;
    let ctx = canvas.getContext("2d");

    ctx.drawImage(videoElem, 0, 0, canvas.width, canvas.height);
    canvasContainer.appendChild(canvas);
    let image = canvas.toDataURL("image/jpeg");
    const ms = Date.now();
    zip.file(`capture_${ms}.png`, image.substr(image.indexOf(",") + 1), {
      base64: true
    });
  },
  false
);

downloadCaptures.addEventListener(
  "click",
  function (evt) {
    zip
      .generateAsync({ type: "blob" }, function updateCallback(metadata) {
        var msg = "progression : " + metadata.percent.toFixed(2) + " %";
        if (metadata.currentFile) {
          msg += ", current file = " + metadata.currentFile;
        }
        console.log(msg);
        console.log(metadata.percent | 0);
      })
      .then(
        function callback(blob) {
          // see FileSaver.js
          const ms = Date.now();
          saveAs(blob, `package_${ms}`);

          console.log("done !");
        },
        function (e) {
          console.log(e);
        }
      );
  },
  false
);
